import { Injectable } from "@angular/core";
import { of as observableOf, Observable } from "rxjs";
import { CountryOrderData } from "../data/country-order";

@Injectable()
export class CountryOrderService extends CountryOrderData {
  private countriesCategories = [
    "+1M",
    "500K - 1M",
    "100K - 500K",
    "50K - 100K",
    "50K > -",
  ];
  private countriesCategoriesLength = this.countriesCategories.length;
  private generateRandomData(nPoints: number): number[] {
    return Array.from(Array(nPoints)).map(() => {
      return Math.round(Math.random() * 20);
    });
  }

  getCountriesCategories(): Observable<string[]> {
    return observableOf(this.countriesCategories);
  }

  getCountriesCategoriesData(country: string): Observable<number[]> {
    return observableOf(
      this.generateRandomData(this.countriesCategoriesLength)
    );
  }
}
