import { Injectable } from "@angular/core";
import { SmartTableData } from "../data/smart-table";

@Injectable()
export class SmartTableService extends SmartTableData {
  users = [
    {
      id: 1,
      firstName: "Sara",
      lastName: "Al-Ghamdi",
      username: "@sal-ghamdi",
      email: "sara.al-ghamdi@jeddah.co",
      age: "45",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-12-23",
      last_verification: "2024-01-13",
      verification_status: "revoked",
    },
    {
      id: 2,
      firstName: "Hassan",
      lastName: "Al-Zahrani",
      username: "@hal-zahrani",
      email: "hassan.al-zahrani@ksa.com",
      age: "38",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-08-27",
      last_verification: "2024-01-02",
      verification_status: "verified",
    },
    {
      id: 3,
      firstName: "Aisha",
      lastName: "Al-Muhaya",
      username: "@aal-muhaya",
      email: "aisha.al-muhaya@ksa.com",
      age: "26",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-01-13",
      last_verification: "2024-01-12",
      verification_status: "revoked",
    },
    {
      id: 4,
      firstName: "Nora",
      lastName: "Al-Sheikh",
      username: "@nal-sheikh",
      email: "nora.al-sheikh@saudi.sa",
      age: "42",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-04-04",
      last_verification: "2024-01-07",
      verification_status: "rejected",
    },
    {
      id: 5,
      firstName: "Mohammed",
      lastName: "Al-Harbi",
      username: "@mal-harbi",
      email: "mohammed.al-harbi@jeddah.co",
      age: "64",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-09-02",
      last_verification: "2024-01-07",
      verification_status: "revoked",
    },
    {
      id: 6,
      firstName: "Mohammed",
      lastName: "Al-Zahrani",
      username: "@mal-zahrani",
      email: "mohammed.al-zahrani@riyadh.net",
      age: "26",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-11-12",
      last_verification: "2024-01-11",
      verification_status: "verified",
    },
    {
      id: 7,
      firstName: "Ahmed",
      lastName: "Al-Muhaya",
      username: "@aal-muhaya",
      email: "ahmed.al-muhaya@medina.sa",
      age: "34",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-06-14",
      last_verification: "2024-01-01",
      verification_status: "rejected",
    },
    {
      id: 8,
      firstName: "Fatima",
      lastName: "Al-Khalifa",
      username: "@fal-khalifa",
      email: "fatima.al-khalifa@riyadh.net",
      age: "58",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-10-09",
      last_verification: "2024-01-11",
      verification_status: "verified",
    },
    {
      id: 9,
      firstName: "Ali",
      lastName: "Al-Fahd",
      username: "@aal-fahd",
      email: "ali.al-fahd@riyadh.net",
      age: "37",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-03-21",
      last_verification: "2024-01-03",
      verification_status: "pending",
    },
    {
      id: 10,
      firstName: "Fatima",
      lastName: "Al-Sheikh",
      username: "@fal-sheikh",
      email: "fatima.al-sheikh@saudi.sa",
      age: "24",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-07-13",
      last_verification: "2024-01-07",
      verification_status: "rejected",
    },
    {
      id: 11,
      firstName: "Ahmed",
      lastName: "Al-Ghamdi",
      username: "@aal-ghamdi",
      email: "ahmed.al-ghamdi@medina.sa",
      age: "55",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-10-13",
      last_verification: "2024-01-01",
      verification_status: "rejected",
    },
    {
      id: 12,
      firstName: "Abdul",
      lastName: "Al-Saud",
      username: "@aal-saud",
      email: "abdul.al-saud@ksa.com",
      age: "32",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-10-04",
      last_verification: "2024-01-09",
      verification_status: "revoked",
    },
    {
      id: 13,
      firstName: "Ahmed",
      lastName: "Al-Khalifa",
      username: "@aal-khalifa",
      email: "ahmed.al-khalifa@ksa.com",
      age: "19",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-07-20",
      last_verification: "2024-01-02",
      verification_status: "verified",
    },
    {
      id: 14,
      firstName: "Mohammed",
      lastName: "Al-Muhaya",
      username: "@mal-muhaya",
      email: "mohammed.al-muhaya@ksa.com",
      age: "34",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-11-06",
      last_verification: "2024-01-01",
      verification_status: "verified",
    },
    {
      id: 15,
      firstName: "Omar",
      lastName: "Al-Muhaya",
      username: "@oal-muhaya",
      email: "omar.al-muhaya@saudi.sa",
      age: "21",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-11-14",
      last_verification: "2024-01-01",
      verification_status: "revoked",
    },
    {
      id: 16,
      firstName: "Hassan",
      lastName: "Al-Fahd",
      username: "@hal-fahd",
      email: "hassan.al-fahd@ksa.com",
      age: "22",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-11-08",
      last_verification: "2024-01-17",
      verification_status: "revoked",
    },
    {
      id: 17,
      firstName: "Sara",
      lastName: "Al-Qahtani",
      username: "@sal-qahtani",
      email: "sara.al-qahtani@jeddah.co",
      age: "48",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-09-07",
      last_verification: "2024-01-04",
      verification_status: "revoked",
    },
    {
      id: 18,
      firstName: "Fatima",
      lastName: "Al-Saud",
      username: "@fal-saud",
      email: "fatima.al-saud@ksa.com",
      age: "36",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-10-11",
      last_verification: "2024-01-02",
      verification_status: "verified",
    },
    {
      id: 19,
      firstName: "Nora",
      lastName: "Al-Harbi",
      username: "@nal-harbi",
      email: "nora.al-harbi@ksa.com",
      age: "62",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-06-22",
      last_verification: "2024-01-10",
      verification_status: "verified",
    },
    {
      id: 20,
      firstName: "Sara",
      lastName: "Al-Sheikh",
      username: "@sal-sheikh",
      email: "sara.al-sheikh@riyadh.net",
      age: "57",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-03-14",
      last_verification: "2024-01-17",
      verification_status: "revoked",
    },
    {
      id: 21,
      firstName: "Fatima",
      lastName: "Al-Fahd",
      username: "@fal-fahd",
      email: "fatima.al-fahd@medina.sa",
      age: "50",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-08-09",
      last_verification: "2024-01-12",
      verification_status: "verified",
    },
    {
      id: 22,
      firstName: "Sara",
      lastName: "Al-Qahtani",
      username: "@sal-qahtani",
      email: "sara.al-qahtani@saudi.sa",
      age: "56",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-09-19",
      last_verification: "2024-01-11",
      verification_status: "revoked",
    },
    {
      id: 23,
      firstName: "Ali",
      lastName: "Al-Ghamdi",
      username: "@aal-ghamdi",
      email: "ali.al-ghamdi@medina.sa",
      age: "41",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-04-20",
      last_verification: "2024-01-06",
      verification_status: "verified",
    },
    {
      id: 24,
      firstName: "Sara",
      lastName: "Al-Ghamdi",
      username: "@sal-ghamdi",
      email: "sara.al-ghamdi@ksa.com",
      age: "28",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-01-24",
      last_verification: "2024-01-11",
      verification_status: "pending",
    },
    {
      id: 25,
      firstName: "Fatima",
      lastName: "Al-Sheikh",
      username: "@fal-sheikh",
      email: "fatima.al-sheikh@medina.sa",
      age: "46",
      isBlocked: true,
      isDeleted: true,
      created_at: "2023-03-15",
      last_verification: "2024-01-04",
      verification_status: "revoked",
    },
    {
      id: 26,
      firstName: "Mohammed",
      lastName: "Al-Fahd",
      username: "@mal-fahd",
      email: "mohammed.al-fahd@riyadh.net",
      age: "34",
      isBlocked: false,
      isDeleted: false,
      created_at: "2023-07-10",
      last_verification: "2024-01-13",
      verification_status: "revoked",
    },
    {
      id: 27,
      firstName: "Nora",
      lastName: "Al-Ahmed",
      username: "@nal-ahmed",
      email: "nora.al-ahmed@jeddah.co",
      age: "55",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-06-28",
      last_verification: "2024-01-07",
      verification_status: "rejected",
    },
    {
      id: 28,
      firstName: "Ali",
      lastName: "Al-Zahrani",
      username: "@aal-zahrani",
      email: "ali.al-zahrani@jeddah.co",
      age: "51",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-04-07",
      last_verification: "2024-01-09",
      verification_status: "rejected",
    },
    {
      id: 29,
      firstName: "Mohammed",
      lastName: "Al-Khalifa",
      username: "@mal-khalifa",
      email: "mohammed.al-khalifa@medina.sa",
      age: "44",
      isBlocked: true,
      isDeleted: false,
      created_at: "2023-07-17",
      last_verification: "2024-01-16",
      verification_status: "verified",
    },
    {
      id: 30,
      firstName: "Abdul",
      lastName: "Al-Qahtani",
      username: "@aal-qahtani",
      email: "abdul.al-qahtani@riyadh.net",
      age: "51",
      isBlocked: false,
      isDeleted: true,
      created_at: "2023-07-09",
      last_verification: "2024-01-18",
      verification_status: "rejected",
    },
  ];

  data = [
    {
      id: 1,
      firstName: "Mark",
      lastName: "Otto",
      username: "@mdo",
      email: "mdo@gmail.com",
      age: "28",
    },
    {
      id: 2,
      firstName: "Jacob",
      lastName: "Thornton",
      username: "@fat",
      email: "fat@yandex.ru",
      age: "45",
    },
    {
      id: 3,
      firstName: "Larry",
      lastName: "Bird",
      username: "@twitter",
      email: "twitter@outlook.com",
      age: "18",
    },
    {
      id: 4,
      firstName: "John",
      lastName: "Snow",
      username: "@snow",
      email: "snow@gmail.com",
      age: "20",
    },
    {
      id: 5,
      firstName: "Jack",
      lastName: "Sparrow",
      username: "@jack",
      email: "jack@yandex.ru",
      age: "30",
    },
    {
      id: 6,
      firstName: "Ann",
      lastName: "Smith",
      username: "@ann",
      email: "ann@gmail.com",
      age: "21",
    },
    {
      id: 7,
      firstName: "Barbara",
      lastName: "Black",
      username: "@barbara",
      email: "barbara@yandex.ru",
      age: "43",
    },
    {
      id: 8,
      firstName: "Sevan",
      lastName: "Bagrat",
      username: "@sevan",
      email: "sevan@outlook.com",
      age: "13",
    },
    {
      id: 9,
      firstName: "Ruben",
      lastName: "Vardan",
      username: "@ruben",
      email: "ruben@gmail.com",
      age: "22",
    },
    {
      id: 10,
      firstName: "Karen",
      lastName: "Sevan",
      username: "@karen",
      email: "karen@yandex.ru",
      age: "33",
    },
    {
      id: 11,
      firstName: "Mark",
      lastName: "Otto",
      username: "@mark",
      email: "mark@gmail.com",
      age: "38",
    },
    {
      id: 12,
      firstName: "Jacob",
      lastName: "Thornton",
      username: "@jacob",
      email: "jacob@yandex.ru",
      age: "48",
    },
    {
      id: 13,
      firstName: "Haik",
      lastName: "Hakob",
      username: "@haik",
      email: "haik@outlook.com",
      age: "48",
    },
    {
      id: 14,
      firstName: "Garegin",
      lastName: "Jirair",
      username: "@garegin",
      email: "garegin@gmail.com",
      age: "40",
    },
    {
      id: 15,
      firstName: "Krikor",
      lastName: "Bedros",
      username: "@krikor",
      email: "krikor@yandex.ru",
      age: "32",
    },
    {
      id: 16,
      firstName: "Francisca",
      lastName: "Brady",
      username: "@Gibson",
      email: "franciscagibson@comtours.com",
      age: 11,
    },
    {
      id: 17,
      firstName: "Tillman",
      lastName: "Figueroa",
      username: "@Snow",
      email: "tillmansnow@comtours.com",
      age: 34,
    },
    {
      id: 18,
      firstName: "Jimenez",
      lastName: "Morris",
      username: "@Bryant",
      email: "jimenezbryant@comtours.com",
      age: 45,
    },
    {
      id: 19,
      firstName: "Sandoval",
      lastName: "Jacobson",
      username: "@Mcbride",
      email: "sandovalmcbride@comtours.com",
      age: 32,
    },
    {
      id: 20,
      firstName: "Griffin",
      lastName: "Torres",
      username: "@Charles",
      email: "griffincharles@comtours.com",
      age: 19,
    },
    {
      id: 21,
      firstName: "Cora",
      lastName: "Parker",
      username: "@Caldwell",
      email: "coracaldwell@comtours.com",
      age: 27,
    },
    {
      id: 22,
      firstName: "Cindy",
      lastName: "Bond",
      username: "@Velez",
      email: "cindyvelez@comtours.com",
      age: 24,
    },
    {
      id: 23,
      firstName: "Frieda",
      lastName: "Tyson",
      username: "@Craig",
      email: "friedacraig@comtours.com",
      age: 45,
    },
    {
      id: 24,
      firstName: "Cote",
      lastName: "Holcomb",
      username: "@Rowe",
      email: "coterowe@comtours.com",
      age: 20,
    },
    {
      id: 25,
      firstName: "Trujillo",
      lastName: "Mejia",
      username: "@Valenzuela",
      email: "trujillovalenzuela@comtours.com",
      age: 16,
    },
    {
      id: 26,
      firstName: "Pruitt",
      lastName: "Shepard",
      username: "@Sloan",
      email: "pruittsloan@comtours.com",
      age: 44,
    },
    {
      id: 27,
      firstName: "Sutton",
      lastName: "Ortega",
      username: "@Black",
      email: "suttonblack@comtours.com",
      age: 42,
    },
    {
      id: 28,
      firstName: "Marion",
      lastName: "Heath",
      username: "@Espinoza",
      email: "marionespinoza@comtours.com",
      age: 47,
    },
    {
      id: 29,
      firstName: "Newman",
      lastName: "Hicks",
      username: "@Keith",
      email: "newmankeith@comtours.com",
      age: 15,
    },
    {
      id: 30,
      firstName: "Boyle",
      lastName: "Larson",
      username: "@Summers",
      email: "boylesummers@comtours.com",
      age: 32,
    },
    {
      id: 31,
      firstName: "Haynes",
      lastName: "Vinson",
      username: "@Mckenzie",
      email: "haynesmckenzie@comtours.com",
      age: 15,
    },
    {
      id: 32,
      firstName: "Miller",
      lastName: "Acosta",
      username: "@Young",
      email: "milleryoung@comtours.com",
      age: 55,
    },
    {
      id: 33,
      firstName: "Johnston",
      lastName: "Brown",
      username: "@Knight",
      email: "johnstonknight@comtours.com",
      age: 29,
    },
    {
      id: 34,
      firstName: "Lena",
      lastName: "Pitts",
      username: "@Forbes",
      email: "lenaforbes@comtours.com",
      age: 25,
    },
    {
      id: 35,
      firstName: "Terrie",
      lastName: "Kennedy",
      username: "@Branch",
      email: "terriebranch@comtours.com",
      age: 37,
    },
    {
      id: 36,
      firstName: "Louise",
      lastName: "Aguirre",
      username: "@Kirby",
      email: "louisekirby@comtours.com",
      age: 44,
    },
    {
      id: 37,
      firstName: "David",
      lastName: "Patton",
      username: "@Sanders",
      email: "davidsanders@comtours.com",
      age: 26,
    },
    {
      id: 38,
      firstName: "Holden",
      lastName: "Barlow",
      username: "@Mckinney",
      email: "holdenmckinney@comtours.com",
      age: 11,
    },
    {
      id: 39,
      firstName: "Baker",
      lastName: "Rivera",
      username: "@Montoya",
      email: "bakermontoya@comtours.com",
      age: 47,
    },
    {
      id: 40,
      firstName: "Belinda",
      lastName: "Lloyd",
      username: "@Calderon",
      email: "belindacalderon@comtours.com",
      age: 21,
    },
    {
      id: 41,
      firstName: "Pearson",
      lastName: "Patrick",
      username: "@Clements",
      email: "pearsonclements@comtours.com",
      age: 42,
    },
    {
      id: 42,
      firstName: "Alyce",
      lastName: "Mckee",
      username: "@Daugherty",
      email: "alycedaugherty@comtours.com",
      age: 55,
    },
    {
      id: 43,
      firstName: "Valencia",
      lastName: "Spence",
      username: "@Olsen",
      email: "valenciaolsen@comtours.com",
      age: 20,
    },
    {
      id: 44,
      firstName: "Leach",
      lastName: "Holcomb",
      username: "@Humphrey",
      email: "leachhumphrey@comtours.com",
      age: 28,
    },
    {
      id: 45,
      firstName: "Moss",
      lastName: "Baxter",
      username: "@Fitzpatrick",
      email: "mossfitzpatrick@comtours.com",
      age: 51,
    },
    {
      id: 46,
      firstName: "Jeanne",
      lastName: "Cooke",
      username: "@Ward",
      email: "jeanneward@comtours.com",
      age: 59,
    },
    {
      id: 47,
      firstName: "Wilma",
      lastName: "Briggs",
      username: "@Kidd",
      email: "wilmakidd@comtours.com",
      age: 53,
    },
    {
      id: 48,
      firstName: "Beatrice",
      lastName: "Perry",
      username: "@Gilbert",
      email: "beatricegilbert@comtours.com",
      age: 39,
    },
    {
      id: 49,
      firstName: "Whitaker",
      lastName: "Hyde",
      username: "@Mcdonald",
      email: "whitakermcdonald@comtours.com",
      age: 35,
    },
    {
      id: 50,
      firstName: "Rebekah",
      lastName: "Duran",
      username: "@Gross",
      email: "rebekahgross@comtours.com",
      age: 40,
    },
    {
      id: 51,
      firstName: "Earline",
      lastName: "Mayer",
      username: "@Woodward",
      email: "earlinewoodward@comtours.com",
      age: 52,
    },
    {
      id: 52,
      firstName: "Moran",
      lastName: "Baxter",
      username: "@Johns",
      email: "moranjohns@comtours.com",
      age: 20,
    },
    {
      id: 53,
      firstName: "Nanette",
      lastName: "Hubbard",
      username: "@Cooke",
      email: "nanettecooke@comtours.com",
      age: 55,
    },
    {
      id: 54,
      firstName: "Dalton",
      lastName: "Walker",
      username: "@Hendricks",
      email: "daltonhendricks@comtours.com",
      age: 25,
    },
    {
      id: 55,
      firstName: "Bennett",
      lastName: "Blake",
      username: "@Pena",
      email: "bennettpena@comtours.com",
      age: 13,
    },
    {
      id: 56,
      firstName: "Kellie",
      lastName: "Horton",
      username: "@Weiss",
      email: "kellieweiss@comtours.com",
      age: 48,
    },
    {
      id: 57,
      firstName: "Hobbs",
      lastName: "Talley",
      username: "@Sanford",
      email: "hobbssanford@comtours.com",
      age: 28,
    },
    {
      id: 58,
      firstName: "Mcguire",
      lastName: "Donaldson",
      username: "@Roman",
      email: "mcguireroman@comtours.com",
      age: 38,
    },
    {
      id: 59,
      firstName: "Rodriquez",
      lastName: "Saunders",
      username: "@Harper",
      email: "rodriquezharper@comtours.com",
      age: 20,
    },
    {
      id: 60,
      firstName: "Lou",
      lastName: "Conner",
      username: "@Sanchez",
      email: "lousanchez@comtours.com",
      age: 16,
    },
  ];

  transactions = [
    {
      id: 1,
      "amount SAR": "6233",
      "amount USD": "1662.13",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Pending",
      numberOfUnits: "13",
      User: {
        id: 22,
        firstName: "Reem",
        lastName: "Al-Rajhi",
        username: "@ral-rajhi",
        email: "reem.al-rajhi@saudi.sa",
        age: "22",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 2,
      "amount SAR": "2639",
      "amount USD": "703.73",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Reviewed",
      numberOfUnits: "null",
      User: {
        id: 32,
        firstName: "Yousef",
        lastName: "Al-Sulaiman",
        username: "@yal-sulaiman",
        email: "yousef.al-sulaiman@saudi.sa",
        age: "41",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 3,
      "amount SAR": "7103",
      "amount USD": "1894.13",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Reviewed",
      numberOfUnits: "14",
      User: {
        id: 31,
        firstName: "Huda",
        lastName: "Al-Mohammed",
        username: "@hal-mohammed",
        email: "huda.al-mohammed@saudi.sa",
        age: "38",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 4,
      "amount SAR": "5098",
      "amount USD": "1359.47",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 45,
        firstName: "Nora",
        lastName: "Al-Shehri",
        username: "@nal-shehri",
        email: "nora.al-shehri@saudi.sa",
        age: "37",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 5,
      "amount SAR": "1735",
      "amount USD": "462.67",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 19,
        firstName: "Abdullah",
        lastName: "Al-Rajhi",
        username: "@aal-rajhi",
        email: "abdullah.al-rajhi@saudi.sa",
        age: "59",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 6,
      "amount SAR": "4887",
      "amount USD": "1303.2",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "UnderReview",
      numberOfUnits: "2",
      User: {
        id: 15,
        firstName: "Rania",
        lastName: "Al-Ghamdi",
        username: "@ral-ghamdi",
        email: "rania.al-ghamdi@saudi.sa",
        age: "51",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 7,
      "amount SAR": "1820",
      "amount USD": "485.33",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Reviewed",
      numberOfUnits: "12",
      User: {
        id: 9,
        firstName: "Rania",
        lastName: "Al-Shehri",
        username: "@ral-shehri",
        email: "rania.al-shehri@saudi.sa",
        age: "43",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 8,
      "amount SAR": "1039",
      "amount USD": "277.07",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "Pending",
      numberOfUnits: "6",
      User: {
        id: 6,
        firstName: "Rania",
        lastName: "Al-Ahmadi",
        username: "@ral-ahmadi",
        email: "rania.al-ahmadi@saudi.sa",
        age: "49",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 9,
      "amount SAR": "6598",
      "amount USD": "1759.47",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 22,
        firstName: "Reem",
        lastName: "Al-Rajhi",
        username: "@ral-rajhi",
        email: "reem.al-rajhi@saudi.sa",
        age: "22",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 10,
      "amount SAR": "6234",
      "amount USD": "1662.4",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Reviewed",
      numberOfUnits: "1",
      User: {
        id: 2,
        firstName: "Rania",
        lastName: "Al-Farsi",
        username: "@ral-farsi",
        email: "rania.al-farsi@saudi.sa",
        age: "48",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 11,
      "amount SAR": "3166",
      "amount USD": "844.27",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 25,
        firstName: "Aisha",
        lastName: "Al-Mutairi",
        username: "@aal-mutairi",
        email: "aisha.al-mutairi@saudi.sa",
        age: "58",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 12,
      "amount SAR": "9417",
      "amount USD": "2511.2",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 15,
        firstName: "Rania",
        lastName: "Al-Ghamdi",
        username: "@ral-ghamdi",
        email: "rania.al-ghamdi@saudi.sa",
        age: "51",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 13,
      "amount SAR": "8373",
      "amount USD": "2232.8",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "UnderReview",
      numberOfUnits: "1",
      User: {
        id: 34,
        firstName: "Sara",
        lastName: "Al-Harbi",
        username: "@sal-harbi",
        email: "sara.al-harbi@saudi.sa",
        age: "59",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 14,
      "amount SAR": "840",
      "amount USD": "224.0",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 41,
        firstName: "Maha",
        lastName: "Al-Ghamdi",
        username: "@mal-ghamdi",
        email: "maha.al-ghamdi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 15,
      "amount SAR": "4089",
      "amount USD": "1090.4",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "14",
      User: {
        id: 47,
        firstName: "Lama",
        lastName: "Al-Mutairi",
        username: "@lal-mutairi",
        email: "lama.al-mutairi@saudi.sa",
        age: "53",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 16,
      "amount SAR": "7123",
      "amount USD": "1899.47",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "18",
      User: {
        id: 2,
        firstName: "Rania",
        lastName: "Al-Farsi",
        username: "@ral-farsi",
        email: "rania.al-farsi@saudi.sa",
        age: "48",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 17,
      "amount SAR": "2700",
      "amount USD": "720.0",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Failed",
      numberOfUnits: "13",
      User: {
        id: 18,
        firstName: "Saleh",
        lastName: "Al-Harbi",
        username: "@sal-harbi",
        email: "saleh.al-harbi@saudi.sa",
        age: "18",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 18,
      "amount SAR": "6393",
      "amount USD": "1704.8",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "Pending",
      numberOfUnits: "15",
      User: {
        id: 38,
        firstName: "Fahad",
        lastName: "Al-Shehri",
        username: "@fal-shehri",
        email: "fahad.al-shehri@saudi.sa",
        age: "43",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 19,
      "amount SAR": "6018",
      "amount USD": "1604.8",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 19,
        firstName: "Abdullah",
        lastName: "Al-Rajhi",
        username: "@aal-rajhi",
        email: "abdullah.al-rajhi@saudi.sa",
        age: "59",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 20,
      "amount SAR": "4965",
      "amount USD": "1324.0",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 41,
        firstName: "Maha",
        lastName: "Al-Ghamdi",
        username: "@mal-ghamdi",
        email: "maha.al-ghamdi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 21,
      "amount SAR": "6492",
      "amount USD": "1731.2",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "17",
      User: {
        id: 35,
        firstName: "Maha",
        lastName: "Al-Sulaiman",
        username: "@mal-sulaiman",
        email: "maha.al-sulaiman@saudi.sa",
        age: "49",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 22,
      "amount SAR": "5317",
      "amount USD": "1417.87",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 34,
        firstName: "Sara",
        lastName: "Al-Harbi",
        username: "@sal-harbi",
        email: "sara.al-harbi@saudi.sa",
        age: "59",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 23,
      "amount SAR": "9110",
      "amount USD": "2429.33",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 38,
        firstName: "Fahad",
        lastName: "Al-Shehri",
        username: "@fal-shehri",
        email: "fahad.al-shehri@saudi.sa",
        age: "43",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 24,
      "amount SAR": "9852",
      "amount USD": "2627.2",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 25,
        firstName: "Aisha",
        lastName: "Al-Mutairi",
        username: "@aal-mutairi",
        email: "aisha.al-mutairi@saudi.sa",
        age: "58",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 25,
      "amount SAR": "3179",
      "amount USD": "847.73",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Reviewed",
      numberOfUnits: "null",
      User: {
        id: 6,
        firstName: "Rania",
        lastName: "Al-Ahmadi",
        username: "@ral-ahmadi",
        email: "rania.al-ahmadi@saudi.sa",
        age: "49",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 26,
      "amount SAR": "9477",
      "amount USD": "2527.2",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "16",
      User: {
        id: 4,
        firstName: "Rania",
        lastName: "Al-Mohammed",
        username: "@ral-mohammed",
        email: "rania.al-mohammed@saudi.sa",
        age: "39",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 27,
      "amount SAR": "7101",
      "amount USD": "1893.6",
      transactionType: "deposit",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 50,
        firstName: "Lama",
        lastName: "Al-Sulaiman",
        username: "@lal-sulaiman",
        email: "lama.al-sulaiman@saudi.sa",
        age: "51",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 28,
      "amount SAR": "4904",
      "amount USD": "1307.73",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "null",
      User: {
        id: 10,
        firstName: "Rania",
        lastName: "Al-Ahmadi",
        username: "@ral-ahmadi",
        email: "rania.al-ahmadi@saudi.sa",
        age: "38",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 29,
      "amount SAR": "3055",
      "amount USD": "814.67",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "UnderReview",
      numberOfUnits: "15",
      User: {
        id: 38,
        firstName: "Fahad",
        lastName: "Al-Shehri",
        username: "@fal-shehri",
        email: "fahad.al-shehri@saudi.sa",
        age: "43",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 30,
      "amount SAR": "5085",
      "amount USD": "1356.0",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Reviewed",
      numberOfUnits: "null",
      User: {
        id: 41,
        firstName: "Maha",
        lastName: "Al-Ghamdi",
        username: "@mal-ghamdi",
        email: "maha.al-ghamdi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 31,
      "amount SAR": "7877",
      "amount USD": "2100.53",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Pending",
      numberOfUnits: "10",
      User: {
        id: 6,
        firstName: "Rania",
        lastName: "Al-Ahmadi",
        username: "@ral-ahmadi",
        email: "rania.al-ahmadi@saudi.sa",
        age: "49",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 32,
      "amount SAR": "3937",
      "amount USD": "1049.87",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Pending",
      numberOfUnits: "7",
      User: {
        id: 35,
        firstName: "Maha",
        lastName: "Al-Sulaiman",
        username: "@mal-sulaiman",
        email: "maha.al-sulaiman@saudi.sa",
        age: "49",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 33,
      "amount SAR": "7282",
      "amount USD": "1941.87",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Reviewed",
      numberOfUnits: "null",
      User: {
        id: 1,
        firstName: "Fatima",
        lastName: "Al-Khalidi",
        username: "@fal-khalidi",
        email: "fatima.al-khalidi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 34,
      "amount SAR": "164",
      "amount USD": "43.73",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "UnderReview",
      numberOfUnits: "5",
      User: {
        id: 7,
        firstName: "Reem",
        lastName: "Al-Mohammed",
        username: "@ral-mohammed",
        email: "reem.al-mohammed@saudi.sa",
        age: "19",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 35,
      "amount SAR": "1252",
      "amount USD": "333.87",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "null",
      User: {
        id: 5,
        firstName: "Nawaf",
        lastName: "Al-Ghamdi",
        username: "@nal-ghamdi",
        email: "nawaf.al-ghamdi@saudi.sa",
        age: "32",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 36,
      "amount SAR": "7743",
      "amount USD": "2064.8",
      transactionType: "withdraw",
      paymentMethod: "ANB",
      transactionStatus: "Failed",
      numberOfUnits: "20",
      User: {
        id: 49,
        firstName: "Lama",
        lastName: "Al-Sulaiman",
        username: "@lal-sulaiman",
        email: "lama.al-sulaiman@saudi.sa",
        age: "55",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 37,
      "amount SAR": "1168",
      "amount USD": "311.47",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "Pending",
      numberOfUnits: "19",
      User: {
        id: 1,
        firstName: "Fatima",
        lastName: "Al-Khalidi",
        username: "@fal-khalidi",
        email: "fatima.al-khalidi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 38,
      "amount SAR": "5139",
      "amount USD": "1370.4",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "20",
      User: {
        id: 39,
        firstName: "Sara",
        lastName: "Al-Rajhi",
        username: "@sal-rajhi",
        email: "sara.al-rajhi@saudi.sa",
        age: "33",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 39,
      "amount SAR": "2642",
      "amount USD": "704.53",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 41,
        firstName: "Maha",
        lastName: "Al-Ghamdi",
        username: "@mal-ghamdi",
        email: "maha.al-ghamdi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 40,
      "amount SAR": "5715",
      "amount USD": "1524.0",
      transactionType: "withdraw",
      paymentMethod: "Mada",
      transactionStatus: "UnderReview",
      numberOfUnits: "2",
      User: {
        id: 10,
        firstName: "Rania",
        lastName: "Al-Ahmadi",
        username: "@ral-ahmadi",
        email: "rania.al-ahmadi@saudi.sa",
        age: "38",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 41,
      "amount SAR": "3485",
      "amount USD": "929.33",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Reviewed",
      numberOfUnits: "null",
      User: {
        id: 11,
        firstName: "Lama",
        lastName: "Al-Mutairi",
        username: "@lal-mutairi",
        email: "lama.al-mutairi@saudi.sa",
        age: "27",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 42,
      "amount SAR": "7342",
      "amount USD": "1957.87",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "UnderReview",
      numberOfUnits: "7",
      User: {
        id: 15,
        firstName: "Rania",
        lastName: "Al-Ghamdi",
        username: "@ral-ghamdi",
        email: "rania.al-ghamdi@saudi.sa",
        age: "51",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 43,
      "amount SAR": "7278",
      "amount USD": "1940.8",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 34,
        firstName: "Sara",
        lastName: "Al-Harbi",
        username: "@sal-harbi",
        email: "sara.al-harbi@saudi.sa",
        age: "59",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 44,
      "amount SAR": "5930",
      "amount USD": "1581.33",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "Failed",
      numberOfUnits: "3",
      User: {
        id: 21,
        firstName: "Lama",
        lastName: "Al-Mohammed",
        username: "@lal-mohammed",
        email: "lama.al-mohammed@saudi.sa",
        age: "19",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 45,
      "amount SAR": "2404",
      "amount USD": "641.07",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "null",
      User: {
        id: 38,
        firstName: "Fahad",
        lastName: "Al-Shehri",
        username: "@fal-shehri",
        email: "fahad.al-shehri@saudi.sa",
        age: "43",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 46,
      "amount SAR": "2894",
      "amount USD": "771.73",
      transactionType: "withdraw",
      paymentMethod: "ApplePayment",
      transactionStatus: "Pending",
      numberOfUnits: "null",
      User: {
        id: 8,
        firstName: "Fahad",
        lastName: "Al-Shehri",
        username: "@fal-shehri",
        email: "fahad.al-shehri@saudi.sa",
        age: "43",
        isBlocked: true,
        isDeleted: false,
      },
    },
    {
      id: 47,
      "amount SAR": "4454",
      "amount USD": "1187.73",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "12",
      User: {
        id: 36,
        firstName: "Huda",
        lastName: "Al-Ahmadi",
        username: "@hal-ahmadi",
        email: "huda.al-ahmadi@saudi.sa",
        age: "57",
        isBlocked: false,
        isDeleted: true,
      },
    },
    {
      id: 48,
      "amount SAR": "2632",
      "amount USD": "701.87",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "UnderReview",
      numberOfUnits: "20",
      User: {
        id: 48,
        firstName: "Fahad",
        lastName: "Al-Farsi",
        username: "@fal-farsi",
        email: "fahad.al-farsi@saudi.sa",
        age: "37",
        isBlocked: false,
        isDeleted: false,
      },
    },
    {
      id: 49,
      "amount SAR": "7410",
      "amount USD": "1976.0",
      transactionType: "deposit",
      paymentMethod: "ApplePayment",
      transactionStatus: "Reviewed",
      numberOfUnits: "7",
      User: {
        id: 29,
        firstName: "Abdullah",
        lastName: "Al-Sulaiman",
        username: "@aal-sulaiman",
        email: "abdullah.al-sulaiman@saudi.sa",
        age: "39",
        isBlocked: true,
        isDeleted: true,
      },
    },
    {
      id: 50,
      "amount SAR": "8281",
      "amount USD": "2208.27",
      transactionType: "deposit",
      paymentMethod: "Mada",
      transactionStatus: "UnderReview",
      numberOfUnits: "null",
      User: {
        id: 7,
        firstName: "Reem",
        lastName: "Al-Mohammed",
        username: "@ral-mohammed",
        email: "reem.al-mohammed@saudi.sa",
        age: "19",
        isBlocked: false,
        isDeleted: true,
      },
    },
  ];

  paymentMethod = [
    {
      id: 1,
      name: "بطاقة مدى",
      transactionType: "Mada",
      plusFeature: "",
      isActive: true,
    },
    {
      id: 2,
      name: "ابل باي",
      transactionType: "ApplePay",
      plusFeature: "",
      isActive: true,
    },
    {
      id: 3,
      name: "التحويل البنكي",
      transactionType: "bankTransfer",
      plusFeature: "الاسرع",
      isActive: true,
    },
  ];
  depositBulk = [
    {
      id: 56,
      "total amount SAR": "9760.0",
      "total amount USD": "2602.67",
      "number of transactions": 1,
      created_at: "2023-03-05 17:08",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#1437056",
      transactions: [
        {
          id: 1,
          "amount SAR": "9760",
          "amount USD": "2602.67",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "Completed",
          numberOfUnits: "null",
          User: {
            id: 67,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "50",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 16,
      "total amount SAR": "20306.0",
      "total amount USD": "5414.93",
      "number of transactions": 4,
      created_at: "2023-08-11 02:17",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#2267086",
      transactions: [
        {
          id: 1,
          "amount SAR": "6305",
          "amount USD": "1681.33",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "Pending",
          numberOfUnits: "null",
          User: {
            id: 18,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "29",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 2,
          "amount SAR": "3327",
          "amount USD": "887.2",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "Pending",
          numberOfUnits: "2",
          User: {
            id: 63,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "57",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 3,
          "amount SAR": "2599",
          "amount USD": "693.07",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Completed",
          numberOfUnits: "null",
          User: {
            id: 74,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "24",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 4,
          "amount SAR": "8075",
          "amount USD": "2153.33",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "Completed",
          numberOfUnits: "1",
          User: {
            id: 15,
            firstName: "Nora",
            lastName: "Al-Muhaya",
            username: "@no_muhaya",
            email: "muhaya@mail.com",
            age: "26",
            isBlocked: false,
            isDeleted: false,
          },
        },
      ],
    },
    {
      id: 14,
      "total amount SAR": "13219.0",
      "total amount USD": "3525.06",
      "number of transactions": 2,
      created_at: "2023-02-13 06:08",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#3943692",
      transactions: [
        {
          id: 1,
          "amount SAR": "4490",
          "amount USD": "1197.33",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "Pending",
          numberOfUnits: "null",
          User: {
            id: 99,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "49",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 2,
          "amount SAR": "8729",
          "amount USD": "2327.73",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Completed",
          numberOfUnits: "null",
          User: {
            id: 43,
            firstName: "Aisha",
            lastName: "Al-Harbi",
            username: "@ai_harbi",
            email: "harbi@mail.com",
            age: "30",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 46,
      "total amount SAR": "9861.0",
      "total amount USD": "2629.6",
      "number of transactions": 1,
      created_at: "2023-11-27 22:38",
      transactionStatus: "Pending",
      numberOfUnitsReturned: "null",
      refranceNumber: "#9112115",
      transactions: [
        {
          id: 1,
          "amount SAR": "9861",
          "amount USD": "2629.6",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "Completed",
          numberOfUnits: "null",
          User: {
            id: 33,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "35",
            isBlocked: false,
            isDeleted: false,
          },
        },
      ],
    },
    {
      id: 40,
      "total amount SAR": "2843.0",
      "total amount USD": "758.13",
      "number of transactions": 1,
      created_at: "2023-06-25 00:54",
      transactionStatus: "Pending",
      numberOfUnitsReturned: "null",
      refranceNumber: "#9387192",
      transactions: [
        {
          id: 1,
          "amount SAR": "2843",
          "amount USD": "758.13",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "Completed",
          numberOfUnits: "null",
          User: {
            id: 93,
            firstName: "Omar",
            lastName: "Al-Khalifa",
            username: "@om_khalifa",
            email: "khalifa@mail.com",
            age: "63",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 70,
      "total amount SAR": "12351.0",
      "total amount USD": "3293.6",
      "number of transactions": 3,
      created_at: "2023-11-12 07:38",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "14",
      refranceNumber: "#8254869",
      transactions: [
        {
          id: 1,
          "amount SAR": "2766",
          "amount USD": "737.6",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "Completed",
          numberOfUnits: "9",
          User: {
            id: 32,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "18",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "1581",
          "amount USD": "421.6",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "Sent",
          numberOfUnits: "null",
          User: {
            id: 66,
            firstName: "Nora",
            lastName: "Al-Muhaya",
            username: "@no_muhaya",
            email: "muhaya@mail.com",
            age: "33",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "8004",
          "amount USD": "2134.4",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "Sent",
          numberOfUnits: "7",
          User: {
            id: 63,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "65",
            isBlocked: true,
            isDeleted: false,
          },
        },
      ],
    },
    {
      id: 83,
      "total amount SAR": "20711.0",
      "total amount USD": "5522.93",
      "number of transactions": 5,
      created_at: "2023-11-17 00:54",
      transactionStatus: "Pending",
      numberOfUnitsReturned: "null",
      refranceNumber: "#",
      transactions: [
        {
          id: 1,
          "amount SAR": "7679",
          "amount USD": "2047.73",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "Pending",
          numberOfUnits: "null",
          User: {
            id: 79,
            firstName: "Mohammed",
            lastName: "Al-Saud",
            username: "@mo_saud",
            email: "saud@mail.com",
            age: "35",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "1036",
          "amount USD": "276.27",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Sent",
          numberOfUnits: "7",
          User: {
            id: 27,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "44",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "1384",
          "amount USD": "369.07",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Sent",
          numberOfUnits: "null",
          User: {
            id: 77,
            firstName: "Mohammed",
            lastName: "Al-Saud",
            username: "@mo_saud",
            email: "saud@mail.com",
            age: "47",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 4,
          "amount SAR": "7484",
          "amount USD": "1995.73",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Completed",
          numberOfUnits: "10",
          User: {
            id: 6,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "56",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 5,
          "amount SAR": "3128",
          "amount USD": "834.13",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Completed",
          numberOfUnits: "12",
          User: {
            id: 6,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "32",
            isBlocked: false,
            isDeleted: false,
          },
        },
      ],
    },
    {
      id: 92,
      "total amount SAR": "25175.0",
      "total amount USD": "6713.34",
      "number of transactions": 5,
      created_at: "2023-07-31 00:27",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "32",
      refranceNumber: "#8189492",
      transactions: [
        {
          id: 1,
          "amount SAR": "7519",
          "amount USD": "2005.07",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Pending",
          numberOfUnits: "null",
          User: {
            id: 74,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "49",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "2673",
          "amount USD": "712.8",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "Pending",
          numberOfUnits: "1",
          User: {
            id: 50,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "61",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 3,
          "amount SAR": "8132",
          "amount USD": "2168.53",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Completed",
          numberOfUnits: "12",
          User: {
            id: 34,
            firstName: "Aisha",
            lastName: "Al-Harbi",
            username: "@ai_harbi",
            email: "harbi@mail.com",
            age: "34",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 4,
          "amount SAR": "3811",
          "amount USD": "1016.27",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Sent",
          numberOfUnits: "6",
          User: {
            id: 28,
            firstName: "Nora",
            lastName: "Al-Muhaya",
            username: "@no_muhaya",
            email: "muhaya@mail.com",
            age: "60",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 5,
          "amount SAR": "3040",
          "amount USD": "810.67",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "Completed",
          numberOfUnits: "1",
          User: {
            id: 95,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "51",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 16,
      "total amount SAR": "11566.0",
      "total amount USD": "3084.27",
      "number of transactions": 2,
      created_at: "2023-12-14 12:35",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#6164316",
      transactions: [
        {
          id: 1,
          "amount SAR": "2200",
          "amount USD": "586.67",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Pending",
          numberOfUnits: "3",
          User: {
            id: 33,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "30",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "9366",
          "amount USD": "2497.6",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Completed",
          numberOfUnits: "8",
          User: {
            id: 44,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "40",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 55,
      "total amount SAR": "7789.0",
      "total amount USD": "2077.07",
      "number of transactions": 2,
      created_at: "2023-12-12 19:22",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "20",
      refranceNumber: "#1279642",
      transactions: [
        {
          id: 1,
          "amount SAR": "3264",
          "amount USD": "870.4",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "Completed",
          numberOfUnits: "9",
          User: {
            id: 80,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "53",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 2,
          "amount SAR": "4525",
          "amount USD": "1206.67",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "Sent",
          numberOfUnits: "null",
          User: {
            id: 52,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "30",
            isBlocked: true,
            isDeleted: false,
          },
        },
      ],
    },
  ];

  withdrawBulk = [
    {
      id: 37,
      "total amount SAR": "17713.0",
      "total amount USD": "4723.47",
      "number of transactions": 3,
      created_at: "2023-12-02 19:56",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "50",
      refranceNumber: "#8763766",
      transactions: [
        {
          id: 1,
          "amount SAR": "4476",
          "amount USD": "1193.6",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 5,
            firstName: "Aisha",
            lastName: "Al-Harbi",
            username: "@ai_harbi",
            email: "harbi@mail.com",
            age: "33",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "4885",
          "amount USD": "1302.67",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "3",
          User: {
            id: 74,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "18",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "8352",
          "amount USD": "2227.2",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "7",
          User: {
            id: 66,
            firstName: "Aisha",
            lastName: "Al-Harbi",
            username: "@ai_harbi",
            email: "harbi@mail.com",
            age: "35",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 7,
      "total amount SAR": "28135.0",
      "total amount USD": "7502.66",
      "number of transactions": 5,
      created_at: "2023-04-01 02:58",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#9375416",
      transactions: [
        {
          id: 1,
          "amount SAR": "3710",
          "amount USD": "989.33",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 13,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "57",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 2,
          "amount SAR": "9995",
          "amount USD": "2665.33",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 18,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "42",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "1969",
          "amount USD": "525.07",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 36,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "36",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 4,
          "amount SAR": "8478",
          "amount USD": "2260.8",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 14,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "41",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 5,
          "amount SAR": "3983",
          "amount USD": "1062.13",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 97,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "43",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 53,
      "total amount SAR": "25647.0",
      "total amount USD": "6839.2",
      "number of transactions": 5,
      created_at: "2023-12-18 21:16",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#4779295",
      transactions: [
        {
          id: 1,
          "amount SAR": "1610",
          "amount USD": "429.33",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "6",
          User: {
            id: 94,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "47",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "9865",
          "amount USD": "2630.67",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "1",
          User: {
            id: 56,
            firstName: "Omar",
            lastName: "Al-Khalifa",
            username: "@om_khalifa",
            email: "khalifa@mail.com",
            age: "19",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "3474",
          "amount USD": "926.4",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 26,
            firstName: "Aisha",
            lastName: "Al-Harbi",
            username: "@ai_harbi",
            email: "harbi@mail.com",
            age: "38",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 4,
          "amount SAR": "5541",
          "amount USD": "1477.6",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "5",
          User: {
            id: 32,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "48",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 5,
          "amount SAR": "5157",
          "amount USD": "1375.2",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "9",
          User: {
            id: 37,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "47",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 54,
      "total amount SAR": "3431.0",
      "total amount USD": "914.93",
      "number of transactions": 1,
      created_at: "2023-09-06 00:55",
      transactionStatus: "Pending",
      numberOfUnitsReturned: "null",
      refranceNumber: "#4536905",
      transactions: [
        {
          id: 1,
          "amount SAR": "3431",
          "amount USD": "914.93",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 23,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "37",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 63,
      "total amount SAR": "29062.0",
      "total amount USD": "7749.87",
      "number of transactions": 5,
      created_at: "2023-12-23 13:49",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "77.2",
      refranceNumber: "#4084548",
      transactions: [
        {
          id: 1,
          "amount SAR": "8322",
          "amount USD": "2219.2",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 26,
            firstName: "Mohammed",
            lastName: "Al-Saud",
            username: "@mo_saud",
            email: "saud@mail.com",
            age: "52",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "4542",
          "amount USD": "1211.2",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "7",
          User: {
            id: 45,
            firstName: "Ahmed",
            lastName: "Al-Sheikh",
            username: "@ah_sheikh",
            email: "sheikh@mail.com",
            age: "58",
            isBlocked: false,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "9022",
          "amount USD": "2405.87",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "9",
          User: {
            id: 77,
            firstName: "Omar",
            lastName: "Al-Khalifa",
            username: "@om_khalifa",
            email: "khalifa@mail.com",
            age: "58",
            isBlocked: false,
            isDeleted: true,
          },
        },
        {
          id: 4,
          "amount SAR": "4342",
          "amount USD": "1157.87",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "9",
          User: {
            id: 76,
            firstName: "Hassan",
            lastName: "Al-Zahrani",
            username: "@ha_zahrani",
            email: "zahrani@mail.com",
            age: "22",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 5,
          "amount SAR": "2834",
          "amount USD": "755.73",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "2",
          User: {
            id: 28,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "51",
            isBlocked: true,
            isDeleted: false,
          },
        },
      ],
    },
    {
      id: 24,
      "total amount SAR": "7033.0",
      "total amount USD": "1875.47",
      "number of transactions": 1,
      created_at: "2023-07-01 03:05",
      transactionStatus: "Sent",
      numberOfUnitsReturned: "null",
      refranceNumber: "#5745097",
      transactions: [
        {
          id: 1,
          "amount SAR": "7033",
          "amount USD": "1875.47",
          transactionType: "deposit",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "8",
          User: {
            id: 44,
            firstName: "Nora",
            lastName: "Al-Muhaya",
            username: "@no_muhaya",
            email: "muhaya@mail.com",
            age: "27",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 21,
      "total amount SAR": "5091.0",
      "total amount USD": "1357.6",
      "number of transactions": 1,
      created_at: "2023-12-22 10:30",
      transactionStatus: "Pending",
      numberOfUnitsReturned: "null",
      refranceNumber: "#1485972",
      transactions: [
        {
          id: 1,
          "amount SAR": "5091",
          "amount USD": "1357.6",
          transactionType: "withdrawal",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "8",
          User: {
            id: 7,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "44",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 33,
      "total amount SAR": "8286.0",
      "total amount USD": "2209.6",
      "number of transactions": 1,
      created_at: "2023-06-17 20:10",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "31.3",
      refranceNumber: "#5430929",
      transactions: [
        {
          id: 1,
          "amount SAR": "8286",
          "amount USD": "2209.6",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 95,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "58",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 20,
      "total amount SAR": "21363.0",
      "total amount USD": "5696.81",
      "number of transactions": 5,
      created_at: "2023-04-13 05:30",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "21.5",
      refranceNumber: "#9786534",
      transactions: [
        {
          id: 1,
          "amount SAR": "5815",
          "amount USD": "1550.67",
          transactionType: "deposit",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "3",
          User: {
            id: 95,
            firstName: "Sara",
            lastName: "Al-Ghamdi",
            username: "@sa_ghamdi",
            email: "ghamdi@mail.com",
            age: "44",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 2,
          "amount SAR": "3760",
          "amount USD": "1002.67",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 71,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "63",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 3,
          "amount SAR": "2716",
          "amount USD": "724.27",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 15,
            firstName: "Nora",
            lastName: "Al-Muhaya",
            username: "@no_muhaya",
            email: "muhaya@mail.com",
            age: "51",
            isBlocked: true,
            isDeleted: true,
          },
        },
        {
          id: 4,
          "amount SAR": "3739",
          "amount USD": "997.07",
          transactionType: "withdrawal",
          paymentMethod: "Credit Card",
          transactionStatus: "withdraw",
          numberOfUnits: "null",
          User: {
            id: 68,
            firstName: "Fatima",
            lastName: "Al-Ahmed",
            username: "@fa_ahmed",
            email: "ahmed@mail.com",
            age: "29",
            isBlocked: true,
            isDeleted: false,
          },
        },
        {
          id: 5,
          "amount SAR": "5333",
          "amount USD": "1422.13",
          transactionType: "withdrawal",
          paymentMethod: "Mada",
          transactionStatus: "withdraw",
          numberOfUnits: "6",
          User: {
            id: 55,
            firstName: "Ali",
            lastName: "Al-Qahtani",
            username: "@al_qahtani",
            email: "qahtani@mail.com",
            age: "40",
            isBlocked: true,
            isDeleted: true,
          },
        },
      ],
    },
    {
      id: 49,
      "total amount SAR": "3858.0",
      "total amount USD": "1028.8",
      "number of transactions": 1,
      created_at: "2023-01-23 01:46",
      transactionStatus: "Completed",
      numberOfUnitsReturned: "42",
      refranceNumber: "#3537322",
      transactions: [
        {
          id: 1,
          "amount SAR": "3858",
          "amount USD": "1028.8",
          transactionType: "deposit",
          paymentMethod: "Cash",
          transactionStatus: "withdraw",
          numberOfUnits: "10",
          User: {
            id: 47,
            firstName: "Abdul",
            lastName: "Al-Fahd",
            username: "@ab_fahd",
            email: "fahd@mail.com",
            age: "37",
            isBlocked: false,
            isDeleted: true,
          },
        },
      ],
    },
  ];

  getUserData() {
    return this.users;
  }
  getTransactionData() {
    return this.transactions;
  }
  getData() {
    return this.data;
  }
  getPaymentMethodData() {
    return this.paymentMethod;
  }

  getDepositBulk() {
    return this.depositBulk;
  }
  getWithdrawBulk() {
    return this.withdrawBulk;
  }
}
